<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="出库单ID">
                <el-input v-model="search.chuKuDanID" size="small"/>
            </x-search-item>
            <x-search-item label="出库时间" textWidth="200px" hasEnd>
                <el-date-picker v-model="search.chuKuSJBegin" size="small" type="datetime" :style="{ width: '100%' }"/>
                <el-date-picker v-model="search.chuKuSJEnd" size="small" type="datetime" slot="end"
                                :style="{ width: '100%' }"/>
            </x-search-item>
            <x-search-item label="出库类型">
                <el-select v-model="search.chuKuLX" placeholder="请选择出库类型" size="small">
                    <el-option :label="ckLX.DBCK.name" :value="ckLX.DBCK.key"></el-option>
                    <el-option v-if="!showOption" :label="ckLX.SQDBCK.name" :value="ckLX.SQDBCK.key"></el-option>
                    <el-option v-if="showOption" :label="ckLX.THZSHCK.name" :value="ckLX.THZSHCK.key"></el-option>
                    <el-option :label="ckLX.THZGHSCK.name" :value="ckLX.THZGHSCK.key"></el-option>
                    <el-option v-if="showOption" :label="ckLX.PKCK.name" :value="ckLX.PKCK.key"></el-option>
                    <el-option :label="ckLX.QTCK.name" :value="ckLX.QTCK.key"></el-option>
                </el-select>
            </x-search-item>

            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
            </div>
        </div>        <!-- 批量按钮区 -->
        <div class="x-page-btn">
            <el-button type="success" size="mini" round @click="handleAdd">新增</el-button>
            <!--            <el-popconfirm title="数据删除后不可恢复，确认删除吗？" @confirm="handleDeleteBatch">-->
            <!--                <el-button type="danger" size="mini" round slot="reference">批量删除</el-button>-->
            <!--            </el-popconfirm>-->
        </div>
        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border
                  @selection-change="handleSelectionChange">
            <el-table-column label="序号" type="index" fixed/>
            <el-table-column prop="caoZuoRen" label="操作人" show-overflow-tooltip/>
            <el-table-column prop="chuKuDanID" label="出库单ID" show-overflow-tooltip/>
            <el-table-column prop="chuKuSJ" label="出库时间" show-overflow-tooltip/>
            <el-table-column prop="caoZuoSJ" label="操作时间" show-overflow-tooltip/>
            <el-table-column prop="chuKuCangKu" label="出库仓库" show-overflow-tooltip/>
            <el-table-column prop="chuKuLX" label="出库类型" show-overflow-tooltip>
            <x-dict-show slot-scope="{row}" :code="row.chuKuLX" dictType="T_CHU_KU_LX" />
            </el-table-column>
            <el-table-column prop="huoZhi" label="货至" show-overflow-tooltip/>
            <el-table-column prop="chuKuShangPinZhongLei" label="出库商品种数" show-overflow-tooltip/>
            <el-table-column prop="chuKuZongJinE" label="出库总金额" show-overflow-tooltip/>
            <el-table-column prop="chengBenJinE" label="成本金额" show-overflow-tooltip/>
            <el-table-column prop="shiShouShangPinZhongShu" label="实收商品种数" show-overflow-tooltip/>
            <el-table-column prop="shiShouJinE" label="实收金额" show-overflow-tooltip/>
            <el-table-column prop="jvShouShangPinZhongShu" label="拒收商品种数" show-overflow-tooltip/>
            <el-table-column prop="jvShouJinE" label="拒收金额" show-overflow-tooltip/>
            <el-table-column prop="yingKuiJinE" label="盈亏金额" show-overflow-tooltip/>
            <el-table-column prop="beiZhu" label="备注" show-overflow-tooltip/>
            <el-table-column width="180" prop="zhuangTai" label="货至状态" show-overflow-tooltip >
                <x-dict-show slot-scope="{row}" :code="row.zhuangTai" dictType="T_RU_KU_STATUS" />
            </el-table-column>
            <el-table-column  width="180" align="center" label="操作" fixed="right">
                <template v-slot="{ row, $index }">
                    <el-button type="success" size="mini" round @click="handleToDetail(row.id)">查看</el-button>
                    <el-button v-if="row.zhuangTai === rkZT.WCKQR.key" type="primary" size="mini" round @click="handleQueRen(row.id)">确认</el-button>
                    <el-popconfirm v-if="row.zhuangTai === rkZT.YJJ.key || row.zhuangTai === rkZT.BFRK.key || row.zhuangTai === rkZT.SHJJ.key" title="确认入库吗？" @confirm="handleDelete(row.id)">
                        <el-button type="success" size="mini" round slot="reference">拒收入库</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 编辑页区域 -->
        <edit ref="edit" :editor="editor" @submitted="refresh"/>
        <detail :detail="detail"/>
    </div>
</template>
<script>
    import * as service from "@/service/cggl/ChuKuXX";
    import XTableBase from "@/components/x/XTableBase";
    import Edit from "@/view/cggl/spckd/ChuKuXXEdit";
    import Detail from "@/view/cggl/spckd/ChuKuXXDetail";
    import {getCangKuList} from "@/service/cggl/TuiHuoDan";
    import {dbUpdateRuKuDan} from "@/service/hlgl/RuKuXX";
    import {TYPE_CHU_KU_LX,TYPE_RU_KU_STATUS,DICT_TYPE_USER_ORGAN_LX} from "@/util/constant"

    export default {
        name: "ChuKuXXList",
        mixins: [XTableBase],
        components: {Edit, Detail},
        data() {
            this.refreshService = service.list;
            this.service = service;
            return {
                ckLX: TYPE_CHU_KU_LX,
                rkZT: TYPE_RU_KU_STATUS,
                options: [],
                search: {
                    chuKuDanID: '',
                    chuKuCangKu: '',
                    chuKuLX: '',
                    chuKuSJBegin: '',
                    chuKuSJEnd: '',
                }
            }
        },
        mounted() {
            this.getCKList()
        },
        methods: {
            handleDelete(id) {
                this.service.jvShouRuKu(id).then((response) => {
                    //提示成功
                    this.$message.success(response.msg);
                    //刷新列表
                    this.refresh();
                });
            },
            handleQueRen(id) {
                dbUpdateRuKuDan(id).then((response) => {
                    //提示成功
                    this.$message.success(response.msg);
                    //刷新列表
                    this.refresh();
                });
            },

            // 获取仓库下拉选择列表
            getCKList() {
                getCangKuList().then((response) => {
                    this.options = response.data
                })
            },
        },
        computed: {
            showOption() {
                return this.$store.getters.user.userOrganLx===DICT_TYPE_USER_ORGAN_LX.MD.key;
            },
        }
    }
</script>

<style scoped>
</style>
