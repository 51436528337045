<template>
    <el-dialog title="详情" :visible.sync="detail.visible" @open="open"
                destroy-on-close center append-to-body width="1000px">
        <x-detail label-width="140px" :entity="entity">
            <x-detail-item prop="chuKuDanID" label="出库单ID" />
            <x-detail-item prop="caoZuoRen" label="操作人" />
            <x-detail-item prop="caoZuoSJ" label="操作时间" />
            <x-detail-item prop="chuKuCangKu" label="出库仓库" />
            <x-detail-item prop="chuKuLX" label="出库类型" >
                <x-dict-show slot-scope="{row}" :code="entity.chuKuLX" dictType="T_CHU_KU_LX" />
            </x-detail-item>
            <x-detail-item prop="huoZhi" label="货至" />
            <x-detail-item prop="chuKuShangPinZhongLei" label="出库商品种类" />
            <x-detail-item prop="chuKuZongJinE" label="出库总金额" />
            <x-detail-item prop="beiZhu" label="备注" />
            <x-detail-item prop="chuKuSJ" label="出库时间" />
        </x-detail>
        <x-divider title="出库信息详情"></x-divider>
        <el-table :data="entity.chuKuXinXiXiangQings" border class="x-detail-table">
            <el-table-column width="60" label="序号" prop="sort" />
            <el-table-column width="150" prop="chuKuLX" label="出库类型" >
                <x-dict-show slot-scope="{row}" :code="entity.chuKuLX" dictType="T_CHU_KU_LX" />
            </el-table-column>
            <el-table-column width="150" prop="shangPinMC" label="商品名称" />
            <el-table-column width="150" prop="guiGe" label="规格" />
            <el-table-column width="150" prop="huoHao" label="货号" />
            <el-table-column width="150" prop="tiaoMa" label="条码" />
            <el-table-column width="150" prop="danWei" label="单位" />
            <el-table-column width="150" prop="chengBenJia" label="成本价" />
            <el-table-column width="150" prop="shengChanRQ" label="生产日期" />
            <el-table-column width="150" prop="baoZhiQi" label="保质期" />
<!--            <el-table-column width="150" prop="fuZhuSL" label="辅助数量" />-->
            <el-table-column width="150" prop="chuKuSL" label="出库数量" />
            <el-table-column width="150" prop="chuKuJia" label="出库价" />
            <el-table-column width="150" prop="xiaoJi" label="小计" />
            <el-table-column width="150" prop="chuKuSJ" label="出库时间" />
            <el-table-column width="150" prop="beiZhu" label="备注" />
        </el-table>
    </el-dialog>
</template>

<script>
    import {getDetail} from "@/service/cggl/ChuKuXX";
    import XDetailBase from "@/components/x/XDetailBase";

    export default {
        mixins: [XDetailBase],
        beforeCreate() {
            this.defaultModel = {
                chuKuDanID: "", // 出库单ID
                caoZuoRen: "", // 操作人
                caoZuoRenID: "", // 操作人ID
                caoZuoSJ: "", // 操作时间
                chuKuCangKu: "", // 出库仓库
                chuKuLX: "", // 出库类型
                huoZhi: "", // 货至
                chuKuShangPinZhongLei: "", // 出库商品种类
                chuKuZongJinE: "", // 出库总金额
                beiZhu: "", // 备注
                chuKuSJ: "", // 出库时间
                chuKuXinXiXiangQings: [],
            };
            this.getDetailService = getDetail;
        }
    }
</script>

<style scoped>

</style>