import request from '@/util/request'

//基础列表
export function list(data) {
    return request({
        url: '/chu-ku-xx/list',
        method: 'post',
        data
    })
}

//添加保存
export function add(data) {
    return request({
        url: '/chu-ku-xx/add',
        method: 'post',
        data
    })
}

//拒收入库 czy
export function jvShouRuKu(id) {
    return request({
        url: `/chu-ku-xx/jv-shou-rk/${id}`,
        method: 'get',
    })
}

//获取商户MC  czy
export function getShangHuMC() {
    return request({
        url: `/chu-ku-xx/get-shang-hu`,
        method: 'get',
    })
}
//获取编辑页数据
export function getUpdate(id) {
    return request({
        url: `/chu-ku-xx/get-update/${id}`,
        method: 'get',
    })
}

//获取详情页数据
export function getDetail(id) {
    return request({
        url: `/chu-ku-xx/get-detail/${id}`,
        method: 'get',
    })
}

//编辑保存
export function update(data) {
    return request({
        url: '/chu-ku-xx/update',
        method: 'post',
        data
    })
}

//删除
export function del(id) {
    return request({
        url: `/chu-ku-xx/delete/${id}`,
        method: 'post',
    })
}

//批量删除
export function deleteBatch(data) {
    return request({
        url: '/chu-ku-xx/delete-batch',
        method: 'post',
        data
    })
}

//独立查询
export function query(data) {
    return request({
        url: '/chu-ku-xx/query',
        method: 'post',
        data
    })
}

/*
列表路由
{
    path: 'chu-ku-xx-list',
    component: () => import('@/view/cggl/spckd/ChuKuXXList'),
    name: 'ChuKuXXList',
    meta: {title: '出库信息', isTag: true, isMenu: true, searchable: true}
}

独立查询路由
{
    path: 'chu-ku-xx-query',
    component: () => import('@/view/cggl/spckd/ChuKuXXQuery'),
    name: 'ChuKuXXQuery',
    meta: {title: '出库信息查询', isTag: true, isMenu: true, searchable: true}
}

提醒列表
{
    path: 'chu-ku-xx-remind-list',
    component: () => import('@/view/cggl/spckd/ChuKuXXRemindList'),
    name: 'ChuKuXXRemindList',
    meta: {title: '出库信息提醒', isTag: true, isMenu: true, searchable: true}
}

菜单识别:基础相关-list为入口
[
{"name":"出库信息基础列表","methodUrl":"/chu-ku-xx/list","operateType":"search","sort":1,"functionClassification":"0","_key":"1"},
{"name":"出库信息添加保存","methodUrl":"/chu-ku-xx/add","operateType":"add","sort":2,"functionClassification":"0","_key":"2"},
{"name":"出库信息获取编辑数据","methodUrl":"/chu-ku-xx/get-update/{id}","operateType":"search","sort":3,"functionClassification":"0","_key":"3"},
{"name":"出库信息获取详情数据","methodUrl":"/chu-ku-xx/get-detail/{id}","operateType":"search","sort":4,"functionClassification":"0","_key":"4"},
{"name":"出库信息编辑保存","methodUrl":"/chu-ku-xx/update","operateType":"edit","sort":5,"functionClassification":"0","_key":"5"},
{"name":"出库信息删除","methodUrl":"/chu-ku-xx/delete/{id}","operateType":"delete","sort":6,"functionClassification":"0","_key":"6"},
{"name":"出库信息批量删除","methodUrl":"/chu-ku-xx/delete-batch","operateType":"delete","sort":7,"functionClassification":"0","_key":"7"}
]
菜单识别:独立查询-query为入口
[
{"name":"出库信息独立查询","methodUrl":"/chu-ku-xx/query","operateType":"search","sort":11,"functionClassification":"0","_key":"11"}
]
*/
