<template>
    <el-dialog :title="title" :visible.sync="editor.visible" @open="open" :close-on-click-modal="false"
               destroy-on-close center append-to-body width="80%">
        <el-form ref="form" :model="form" :rules="rules" class="x-edit">
            <tr class="highlighted">
                <td colspan="3">(如需退货请务必先选货至后再搜索商品！)</td>
            </tr>
            <x-divider title="基本信息"></x-divider>
            <el-form-item label="出库类型" prop="chuKuLX">
                <el-select v-model="form.chuKuLX" placeholder="请选择出库类型" clearable>
                    <el-option :label="cklx.DBCK.name" :value="cklx.DBCK.key"></el-option>
                    <el-option v-if="showOption"  @click.native="fuZhiHuoZhi" :label="cklx.THZSHCK.name" :value="cklx.THZSHCK.key"></el-option>
                    <el-option :label="cklx.THZGHSCK.name" :value="cklx.THZGHSCK.key"></el-option>
                    <el-option :label="cklx.QTCK.name" :value="cklx.QTCK.key"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="商户" prop="shangHu" v-if ="showShangHu" v-model="form.huoZhi" >
                    {{ getShangHu }}
            </el-form-item>

            <el-form-item label="货至" prop="huoZhi" v-if ="showHuoZhi" >
                <el-select v-model="form.huoZhi" size="small" maxlength="36" placeholder="请选择货至方" show-word-limit clearable>
                    <el-option v-if="showMenDian" v-for="item in menDianOptions" :key="item.huoZhi" :label="item.huoZhi"
                               :value="item.menDianMC"  :disabled="isDisabled(item.menDianMC)" ></el-option>
                    <el-option v-if="showGongHuoShang" v-for="item in gongHuoShangOptions" :key="item.huoZhi"
                               :label="item.huoZhi"
                               :value="item.gongHuoShangMC"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="出库时间" prop="chuKuSJ">
                <el-date-picker v-model="form.chuKuSJ" size="small" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"/>
            </el-form-item>
            <el-form-item label="备注" prop="beiZhu">
                <el-input v-model="form.beiZhu" size="small" maxlength="64" show-word-limit/>
            </el-form-item>
            <x-divider title="出库信息详情"></x-divider>
            <x-selector
                    placement="center"
                    selector="KuCunZhuangKuangSelector"
                    @selected="handleClSelect"
                    code-key="id"
                    v-if="form.chuKuLX === cklx.DBCK.key || form.chuKuLX === cklx.QTCK.key "
                    :param="{'cangKuMC': form.chuKuCangKu}"
                    selection
                    width="1000px">
                <el-button size="mini" type="success" round>添加仓库商品</el-button>
            </x-selector>
            <x-selector
                    placement="center"
                    selector="KuCunZhuangKuangSelector"
                    @selected="handleClSelect"
                    code-key="id"
                    v-if="form.chuKuLX === cklx.THZSHCK.key"
                    :param="{'gongHuoShang': getShangHu}"
                    selection
                    width="1000px">
                <el-button size="mini" type="success" round>添加仓库商品</el-button>
            </x-selector>
            <x-selector
                    placement="center"
                    selector="KuCunZhuangKuangSelector"
                    @selected="handleClSelect"
                    code-key="id"
                    v-if="form.chuKuLX === cklx.THZGHSCK.key"
                    :param="{'gongHuoShang': form.huoZhi}"
                    selection
                    width="1000px">
                <el-button size="mini" type="success" round>添加仓库商品</el-button>
            </x-selector>

            <x-row :rows="form.chuKuXinXiXiangQings" :rowModel="chuKuXinXiXiangQingsModel" no-add-btn>
                <x-row-cell  read="shangPinMC" title="商品名称"/>
                <x-row-cell  read="kuCunSL" title="实际库存"/>
                <x-row-cell  read="chengBenJia" title="成本价"/>
                <x-row-cell title="出库数量" prop="chuKuSL" >
                    <el-input slot-scope="{row}" v-model="row.chuKuSL" size="small" maxlength="8"/>
                </x-row-cell>
                <x-row-cell title="出库价" prop="chuKuJia" >
                    <el-input slot-scope="{row}" v-model="row.chuKuJia" size="small" maxlength="8"/>
                </x-row-cell>
                <x-row-cell title="生产日期" width="160" prop="shengChanRQ" >
                    <el-input slot-scope="{row}" v-model="row.shengChanRQ" size="small" maxlength="8" type="date" value-format="yyyy-MM-dd"/>
                </x-row-cell>
                <x-row-cell title="保质期（天）" width="100" prop="baoZhiQi" >
                    <el-input slot-scope="{row}" v-model="row.baoZhiQi" size="small" maxlength="8" />
                </x-row-cell>
                <x-row-cell  read="cangKuMC" title="仓库名称"/>
                <x-row-cell  read="guiGe" title="规格"/>
                <x-row-cell  read="danWei" title="单位"/>
                <x-row-cell  read="kuCunJinE" title="库存金额"/>
                <x-row-cell title="备注" width="100">
                    <el-input slot-scope="{row}" v-model="row.beiZhu" size="small" maxlength="64"/>
                </x-row-cell>
            </x-row>
        </el-form>

        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="save" icon="el-icon-document-add">保 存</el-button>
            <el-button @click="cancel" icon="el-icon-switch-button">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {add, update, getUpdate, getShangHuMC} from "@/service/cggl/ChuKuXX";
    import XEditBase from "@/components/x/XEditBase";
    import {ruleBuilder} from '@/util/validate';
    import {getCangKuList} from "@/service/cggl/TuiHuoDan";
    import XUserSelector from "@/components/x/selector/XUserSelector";
    import {TYPE_CHU_KU_LX, DICT_TYPE_USER_ORGAN_LX} from "@/util/constant";
    import {checkboxList} from "@/service/mdgl/MenDianXX";
    import KuCunZhuangKuangSelector from "@/view/selector/KuCunZhuangKuangSelector";
    import {getGongHuoShangList} from "@/service/cggl/CaiGouDan";

    export default {
        mixins: [XEditBase],
        components: {XUserSelector, KuCunZhuangKuangSelector},
        beforeCreate() {
            let {required} = ruleBuilder;
            this.titlePrefix = '出库信息';
            this.chuKuXinXiXiangQingsModel = {
                chuKuDanID: "", // 出库单ID
                chuKuLX: "", // 出库类型
                shangPinMC: "", // 商品名称
                shangPinID: "", // 商品ID
                guiGe: "", // 规格
                huoHao: "", // 货号
                tiaoMa: "", // 条码
                danWei: "", // 单位
                chengBenJia: "", // 成本价
                shengChanRQ: "", // 生产日期
                baoZhiQi: "", // 保质期
                fuZhuSL: "", // 辅助数量
                chuKuSL: "0", // 出库数量
                chuKuJia: "", // 出库价
                xiaoJi: "", // 小计
                chuKuSJ: "", // 出库时间
                beiZhu: "", // 备注
            }
            this.defaultForm = {
                id: null,
                chuKuDanID: "", // 出库单ID
                caoZuoRenID: this.$store.getters.user.id, // 操作人ID
                caoZuoRen: this.$store.getters.user.name, // 操作人
                caoZuoSJ: "", // 操作时间
                chuKuCangKu: "", // 出库仓库
                chuKuLX: "", // 出库类型
                huoZhi: "", // 货至
                chuKuShangPinZhongLei: "", // 出库商品种类
                chuKuZongJinE: "", // 出库总金额
                beiZhu: "", // 备注
                chuKuSJ: "", // 出库时间
                currentMenDian: this.$store.getters.user.ownerDeptName ,
                chuKuXinXiXiangQings: [],
            };
            this.addService = add;
            this.updateService = update;
            this.getUpdateService = getUpdate;
        },
        data() {
            let {required,digits,positiveEightTwo} = ruleBuilder;
            return {
                rules:{
                    chuKuSL: [required(),positiveEightTwo()],  // 出库数量
                    chuKuJia: [required(),positiveEightTwo()],  // 出库价
                    shengChanRQ: [required()],  // 生产日期
                    baoZhiQi: [required(),digits()],  // 保质期
                    chuKuDanID: [], // 出库单ID
                    caoZuoRen: [], // 操作人
                    caoZuoRenID: [], // 操作人ID
                    caoZuoSJ: [], // 操作时间
                    chuKuCangKu: [], // 出库仓库
                    chuKuLX: [required()], // 出库类型
                    huoZhi: [required()], // 货至
                    shangHu: [], // 商户
                    chuKuShangPinZhongLei: [], // 出库商品种类
                    chuKuZongJinE: [required()], // 出库总金额
                    beiZhu: [], // 备注
                    chuKuSJ: [required()], // 出库时间
                },
                form: {
                    chuKuLX: '',
                    huoZhi: ''
                },
                currentMenDian: this.$store.getters.user.ownerDeptName ,
                cklx:TYPE_CHU_KU_LX,
                showHuoZhi: false,
                showShangHu: false,
                showMenDian: false,
                showGongHuoShang: false,
                getShangHu: '',
                options: [],
                gongHuoShangOptions: [],
                menDianOptions: [],
                chooseTable: [],
            }
        },
        watch: {
            'form.chuKuLX': function (val) {
                if (val === this.cklx.QTCK.key || val === this.cklx.THZSHCK.key || val === this.cklx.PKCK.key) {
                    // 如果选择的出库类型是DBCK，则不显示货至输入框
                    this.showHuoZhi = false;
                } else {
                    // 其他类型则显示货至输入框
                    this.showHuoZhi = true;
                }
                if (val === this.cklx.DBCK.key) {
                    this.showMenDian = true;
                } else {
                    this.showMenDian = false;
                }
                if (val === this.cklx.THZGHSCK.key) {
                    this.showGongHuoShang = true;
                } else {
                    this.showGongHuoShang = false;
                }
                if (val === this.cklx.THZSHCK.key) {
                    this.showShangHu = true;
                } else {
                    this.showShangHu = false;
                }
            },
            immediate: true // 立即执行回调函数
        },
        computed: {
            showOption() {
               // console.log(this.$store.getters.user, 888888888888)
                return this.$store.getters.user.userOrganLx===DICT_TYPE_USER_ORGAN_LX.MD.key;
            }
        },
        mounted() {
            this.getCKList(),
                this.getMenDianCheckboxList(),
                this.getGHSList(),
                this.getShangHuMCs()
        },
        methods: {
            handleClSelect(cls) {
                // cls是查出来的数据
                let cc = [];
                this.form.chuKuXinXiXiangQings.forEach(item => cc.push(item.shangPinID));
                cls.forEach(cl => {
                    //obj是子表数据
                    let obj = {};
                    if(!cc.includes(cl.shangPinId)) {
                        obj.kuCunZhuangKuangBiaoID = cl.id || '';
                        obj.cangKuBH = cl.cangKuBH || '';
                        obj.cangKuMC = cl.cangKuMC || '';
                        obj.shangPinID = cl.shangPinId || '';
                        obj.shangPinMC = cl.shangPinMC || '';
                        obj.tiaoMa = cl.shangPinTiaoMa || '';
                        obj.guiGe = cl.shangPinGuiGe || '';
                        obj.huoHao = cl.shangPinHuoHao || '';
                        obj.danWei = cl.shangPinDW || '';
                        obj.shengChanRQ = cl.shengChanRQ || '';
                        obj.baoZhiQi = cl.baoZhiQiTian || '';
                        obj.kuCunSL = cl.shiJiKuCun || '';
                        obj.chengBenJia = cl.chengBenJia || '';
                        obj.chuKuJia = cl.chengBenJia || '';
                        obj.kuCunJinE = cl.kuCunJinE || '';
                        this.form.chuKuXinXiXiangQings.push(obj);
                    }
                });
            },
            async save(callback) { // 通用save方法 增加回调
                this.loading = true;
                let isvalidate = false;
                try {
                    await this.$refs.form.validate((valid, message) => {
                        if (valid) { // 如果校验通过则提交
                            isvalidate = true;
                        } else { // 校验不通过
                            this.$message.error("有必填项没有填写请检查！");
                            this.loading = false;
                        }
                    });
                } catch (e) {
                    this.loading = false;
                    return;
                }
                if (!isvalidate) return;
                let api = this.isUpdate ? this.updateService : this.addService;

                var rows = this.form.chuKuXinXiXiangQings;
                for (var i = 0; i < rows.length; i++) {
                    var row = rows[i];
                    if (row.chuKuSL <= 0 || row.chuKuSL === null){
                        this.$message.error("出库数量参数异常，出库数量需大于0");
                        return;
                    }
                    if (row.chuKuJia <= 0 || row.chuKuJia === null){
                        this.$message.error("出库价参数异常，出库价需大于0");
                        return;
                    }
                }
                const response = await api(this.form).finally(() => {
                    this.loading = false; //TODO 更好的处理保存按钮加载问题
                });//执行保存方法
                if (callback && typeof callback === 'function') {
                    callback(response);
                }
                //打印提示信息
                this.$message.success(response.msg);
                this.$emit("submitted", response);
                this.cancel();
            },
            isDisabled(menDianMC) {
                // 如果当前选项的门店名称等于当前用户的门店，返回true，表示不可操作
                return menDianMC === this.currentMenDian;
            },
            // 获取门店信息
            getMenDianCheckboxList() {
                checkboxList().then((response) => {
                    this.menDianOptions = response.data
                })
            },
            // 获取仓库下拉选择列表
            getCKList() {
                getCangKuList().then((response) => {
                    this.options = response.data
                })
            },
            getGHSList() {
                getGongHuoShangList().then((response) => {
                    this.gongHuoShangOptions = response.data
                })
            },
            getShangHuMCs() {
                getShangHuMC().then((response) => {
                   // console.log(response.data)
                    this.getShangHu = response.data
                })
            },
            fuZhiHuoZhi(){
                this.form.huoZhi = this.getShangHu
            }
        }

    }
</script>

<style scoped>
    table {
        border-collapse: collapse;
        width: 100%;
    }

    th, td {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
    }

    th {
        background-color: #f2f2f2; /* 设置表头背景颜色 */
    }

    .highlighted {
        background-color: yellow; /* 设置醒目颜色 */
    }
</style>